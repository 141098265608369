import * as React from "react"
import Layout from '../components/layout'
import { Helmet } from "react-helmet"

const Blog = ({ pageContext }) => {
    const { blog } = pageContext
    const bodyText = blog.body?.childMarkdownRemark.html

    return (
        <Layout>
        <Helmet>
          <title>{blog.title}</title>
        </Helmet>
        <h1>{blog.title}</h1>
        <div
          className="body"
          dangerouslySetInnerHTML={{
            __html: bodyText,
          }}
        />
      </Layout>
    )
}

export default Blog
